<template>
  <div class="has_login_box">
    <div class="user-wrap flex">
      <div class="game flex">
        <img src="@/assets/images/mycard/game-icon.png" alt="" />
        <span>{{ gameName }}</span>
        <p v-if="isH5" class="check-coupon flex" @click="showCouponPop">
          {{ $t("myCard.checkExchangeCoupon") }}
          <img src="@/assets/images/mycard/arrow-pink.png" alt="" />
        </p>
      </div>
      <div class="user-login flex" @click.stop="showUserModal = true">
        <img
          src="@/assets/images/mycard/user-icon.png"
          alt=""
          class="user-icon"
        />
        <div class="text" v-if="roleList && roleList.length">
          <p>{{ name }}</p>
          <span>{{
            chooseRoleInfo.server + " - " + chooseRoleInfo.role_name
          }}</span>
        </div>
        <img
          v-if="roleList && roleList.length"
          src="@/assets/images/mycard/change.png"
          alt=""
        />
        <div class="text" v-else>
          <p style="padding-left: 15px">{{ $t("myCard.noGameRole") }}</p>
        </div>
      </div>
    </div>
    <div class="user-modal" v-show="showUserModal">
      <div class="user-main">
        <div class="user-close" @click="closeUserModal">
          <img src="@/assets/images/close.png" alt="" />
        </div>
        <div class="user-title">{{ $t("myCard.chooseRole") }}</div>
        <ul>
          <li
            v-for="(item, index) in roleList"
            :key="index"
            @click.stop="chooseRole(item)"
            :class="item.id === tempUserInfo.id ? 'active' : ''"
          >
            <span>{{ name }}</span
            >{{ item.server + " - " + item.role_name }}
          </li>
        </ul>
        <div class="btns">
          <span @click="closeUserModal">{{ $t("toast.comfirmBtn5") }}</span>
          <span class="confirm-btn" @click="confirmUserRole">{{
            $t("toast.comfirmBtn2")
          }}</span>
        </div>
      </div>
    </div>
    <order-list
      v-if="isOrderList"
      :is-h5="isH5"
      :chooseRoleInfo="chooseRoleInfo"
      ref="orderList"
    ></order-list>
    <pay-gift-h5
      v-if="isH5"
      :chooseRoleInfo="chooseRoleInfo"
      :isOrderList="isOrderList"
      ref="payGift"
    ></pay-gift-h5>
    <pay-gift
      v-if="!isOrderList && !isH5"
      :chooseRoleInfo="chooseRoleInfo"
      ref="payGift"
    ></pay-gift>
  </div>
</template>

<script>
import {
  getCookie,
  getLocalStorage,
  getToken,
  removeLocalStorage,
  removesessionStorage,
  removeToken,
  setLocalStorage,
} from "@/utils/stage";
import { getGameAll } from "@/api/mycard";
import payGift from "@/views/MyCard/components/payGift";
import payGiftH5 from "@/views/MyCard/components/payGiftH5";
import OrderList from "@/views/MyCard/components/orderList";
import staticServerList from "./server";

export default {
  name: "payOnline",
  components: { payGift, payGiftH5, OrderList },
  props: {
    isH5: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      payWay: "mycard",
      name: "",
      email: "",
      uuid: "",
      emailFlag: true,
      imgSrc: require("@/assets/images/google_icon.png"),
      accessToken: "",
      projectId: "",
      roleList: [],
      chooseRoleInfo: {},
      lang: this.$i18n.locale,
      gameName: this.$gameName,
      showUserModal: false,
      tempUserInfo: {},
      isOrderList: this.$route.path.indexOf("/orderList") > -1
    };
  },
  created() {
    this.init();
  },
  mounted() {
    // document.addEventListener("click", this.handleClose);
  },
  methods: {
    init() {
      const data = JSON.parse(getLocalStorage("accountStorage"));
      data ? (this.accessToken = data.access_token) : "";
      if (data) {
        // 账号显示规则：email字段是否绑定
        // 1.未绑定email就是三方账号，显示nickname（nickname存在空，则显示username）
        // 2.已绑定email， 判断has_set_username，has_set_username=1显示username、has_set_username=0显示email
        if (data.email && data.email.length > 0) {
          if (data.has_set_username === 1) {
            this.name = data.username;
          } else {
            this.name = data.email;
          }
        } else {
          if (data.nickname && data.nickname.length > 0) {
            this.name = data.nickname;
          } else {
            this.name = data.username;
          }
        }

        this.uuid = data.open_id;
        if (data.email) {
          this.email = data.email;
        } else {
          this.email = "-";
          this.emailFlag = false;
        }
      } else {
        if (getLocalStorage("loginAccount")) {
          this.name = getLocalStorage("loginAccount");
          this.accessToken = getCookie("access_token");
        } else {
          this.$router.push("/");
        }
      }
      if (getToken("login") != "success" && !getCookie("access_token")) {
        this.$router.push("/");
      }
      this.$emit("loginSuccess", true, this.name);
      this.getGameAll();
    },
    getGameAll() {
      getGameAll({
        access_token: this.accessToken,
        project_id: getLocalStorage("projectId"),
      }).then((res) => {
        if (
          window.location.href.indexOf("payment.catfantasygame.com") > -1 ||
          window.location.href.indexOf("paymenttw.catfantasygame.com") > -1
        ) {
          res.data.list.forEach((item) => {
            staticServerList[this.lang].forEach((each) => {
              if (item.server_id === each.server_id) {
                item.server = each.server_name;
              } else {
                !item.server || item.server.length === 0
                  ? (item.server = "伺服器")
                  : "";
              }
            });
          });
        } else {
          res.data.list.forEach((item) => {
            !item.server || item.server.length === 0
              ? (item.server = "伺服器")
              : "";
          });
        }

        const arr = res.data.list.sort((a, b) => a.updated_at - b.updated_at);
        this.roleList = arr;
        let arrPay = JSON.parse(getLocalStorage("payTime")) || [];
        let payIndex = -1;
        let payObj = {};
        arrPay.forEach((item) => {
          this.roleList.forEach((role, index) => {
            if (
              item.role_id === role.role_id &&
              item.open_id ===
                JSON.parse(getLocalStorage("accountStorage")).open_id
            ) {
              role.payTime = item.payTime;
              payIndex = index;
              payObj = role;
            }
          });
        });

        if (payIndex > 0) {
          this.roleList.splice(payIndex, 1);
          this.roleList.unshift(payObj);
        }
        if (this.roleList && this.roleList[0]) {
          this.chooseRoleInfo = this.roleList[0];
          this.tempUserInfo = this.roleList[0];
          setLocalStorage(
            "chooseRoleInfo",
            JSON.stringify(this.chooseRoleInfo)
          );
        }
        this.roleList && this.roleList.length > 1
          ? (this.showUserModal = true)
          : "";
        // this.gameList = res.data.list;
        // if (this.gameList && this.gameList.length > 0) {
        //   this.gameList.unshift({ game: "请选择", id: -1 });
        // }
      });
    },
    closeUserModal() {
      this.showUserModal = false;
    },
    showCouponPop() {
      this.$refs.payGift.myCoupon();
      this.$refs.payGift.showPop = true;
    },
    // handleClose() {
    //   this.showRoleList = false;
    // },
    confirmUserRole() {
      this.chooseRoleInfo = this.tempUserInfo;
      this.showUserModal = false;
      removeLocalStorage("chooseProduct");
      setLocalStorage("chooseRoleInfo", JSON.stringify(this.chooseRoleInfo));
      removeLocalStorage("chooseProduct");
      removeLocalStorage("pointPrice");
      removeLocalStorage("pointNum");
      removeLocalStorage("pointExtraNum");
      if(!this.isOrderList) {
        this.$refs.payGift.myCoupon();
        this.$refs.payGift.chooseProduct = {};
      }
    },
    chooseRole(item) {
      this.tempUserInfo = item;
      // this.chooseRoleInfo = item;
    },
    loginOut() {
      removeToken("login");
      removesessionStorage("accountStorage");
      removeLocalStorage("accountStorage");
      this.$emit("loginSuccess", false);
      this.$router.push("/");
    },
  },
};
</script>
