const staticServerList = {
  en: [
    {
      server_id: 1,
      server_name: "PTR Server",
    },
    {
      server_id: 3,
      server_name: "image",
    },
    {
      server_id: 4,
      server_name: "Quince",
    },
  ],
  tw: [
    {
      server_id: 1,
      server_name: "昆士區",
    },
    {
      server_id: 2,
      server_name: "南港區",
    },
    {
      server_id: 3,
      server_name: "耀州區",
    },
    {
      server_id: 4,
      server_name: "舊城區",
    },
    {
      server_id: 5,
      server_name: "中心區",
    },
    {
      server_id: 6,
      server_name: "凜北區",
    },
  ],
};

export default staticServerList;
