<template>
  <div class="orderList">
    <div class="order-title">
      <h3>{{ $t("myCard.orderListTitle") }}</h3>
      <p>{{ $t("myCard.orderListSecTitle") }}</p>
    </div>

    <div class="order-tab">
      <span
        v-for="(item, index) in tabList"
        :key="index"
        :class="activeTab === item.value ? 'active' : ''"
        @click="changeTab(item)"
        >{{ item.label }}</span
      >
    </div>

    <div class="list-main">
      <div class="empty" v-if="orderList.length === 0">
        <img src="@/assets/images/mycardH5/empty.png" alt="" />
        <p>{{ $t("myCard.orderListEmpty") }}</p>
      </div>
      <div
        class="order-each"
        v-for="(item, index) in orderList"
        :key="index"
        v-if="item.post_amount !== 0"
      >
        <div class="order-each-top">
          <div class="order-each-title">
            <p>{{ item.product_subject }}</p>
            <template v-if="!isH5 && item.coupon">
              <span v-for="(coupon, index2) in item.coupon" :key="index2">{{
                coupon.name
              }}</span>
            </template>
          </div>
          <span>{{ showStatus(item) }}</span>
        </div>
        <div class="order-each-main">
          <div class="order-each-img">
            <div class="img">
              <img
                src="https://kr-update.fungoglobal.com/cat_web/goods_icon_80006.png?2024"
                alt=""
              />
            </div>
            <div class="order-each-text">
              <p>
                {{ showPayBtns(item) }}
                {{ item.pg_code ? "- " + item.pg_code : "" }}
                {{ $t("myCard.orderListPayWay") }}
              </p>
              <p v-if="item.pay_at">
                {{ $t("myCard.orderListPayTime") }} {{ item.pay_at }}
              </p>
              <p v-else>
                {{ $t("myCard.orderListCreateTime") }} {{ item.created_at }}
              </p>
              <p>{{ $t("myCard.orderListNum") }} {{ item.out_trade_no }}</p>
            </div>
          </div>
          <div
            class="order-each-price"
            v-if="item.amount && Number(item.amount) > -1"
          >
            <i>{{ item.currency_code }} {{ item.amount }}</i>
            <p>{{ $t("myCard.orderListExtra") }}</p>
          </div>
        </div>
        <div class="coupons" v-if="isH5 && item.coupon">
          <span v-for="(coupon, index2) in item.coupon" :key="index2">{{
            coupon.name
          }}</span>
        </div>
        <div class="order-each-btn">
          <div>
            <p v-if="item.close_cd && item.close_cd > 0 && item.status === 0">
              {{ $t("myCard.orderListCloseTime") }} {{ formatTime(item) }}
            </p>
          </div>
          <div class="btns" v-if="item.status === 0">
            <span @click="payClose(item)">{{
              $t("myCard.orderListClose")
            }}</span>
            <span class="active" @click="goPay(item)" v-if="!isKr">{{
              $t("myCard.orderListPay")
            }}</span>
          </div>
        </div>
      </div>
    </div>

    <div
      class="load-more"
      ref="loadMore"
      v-if="orderList.length > 0 && orderList.length < page.total"
    >
      <p>{{ $t("myCard.loadMore") }}</p>
      <img src="@/assets/images/mycard/arrow-down.png" alt="" />
    </div>

    <loading v-if="showLoading"></loading>

    <toast :message="message" v-if="message.length > 0"></toast>

    <div v-if="showPayStatus" class="fix-bg">
      <div class="choose-status" v-if="payStatus === -99">
        <p>{{ $t("myCard.pay1") }}</p>
        <div class="flex">
          <span @click="closeStatus">{{ $t("toast.comfirmBtn4") }}</span>
          <span class="active" @click="payQuery">{{ $t("myCard.pay2") }}</span>
        </div>
      </div>
      <template v-else>
        <div class="success" v-if="payStatus === 2 || payStatus === 4">
          <img src="@/assets/images/mycard/success.png" alt="" />
          <h4>{{ $t("myCard.pay2") }}</h4>
          <p>{{ $t("myCard.pay5") }}</p>
          <div class="btn" @click="closeStatus">
            {{ $t("toast.comfirmBtn2") }}
          </div>
        </div>
        <div class="fail" v-else>
          <img src="@/assets/images/mycard/fail.png" alt="" />
          <h4>{{ $t("myCard.pay3") }}</h4>
          <p>{{ $t("myCard.pay4") }}</p>
          <div class="btn" @click="closeStatus">
            {{ $t("toast.comfirmBtn2") }}
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { myTrade, payClose, payQuery, webItemList } from "@/api/mycard";
import loading from "@/views/MyCard/components/loading";
import staticGiftList from "@/views/MyCard/components/giftList";
import {
  getLocalStorage,
  getSessionStorage,
  setLocalStorage,
} from "@/utils/stage";
import toast from "@/views/MyCard/components/toast";

export default {
  name: "orderList",
  props: {
    isH5: {
      type: Boolean,
      default: false,
    },
    chooseRoleInfo: {},
  },
  components: {
    loading,
    toast,
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  data() {
    return {
      tabList: [
        // 查询用
        { label: this.$t("myCard.orderTab1"), value: 0 }, // 全部
        { label: this.$t("myCard.orderTab2"), value: 1 }, // 未支付
        { label: this.$t("myCard.orderTab3"), value: 2 }, // 已支付
        { label: this.$t("myCard.orderTab4"), value: 3 }, // 已取消
      ],
      stateOption: [
        // 展示用
        { label: this.$t("myCard.orderTab2"), value: 0 }, // 未支付
        { label: this.$t("myCard.orderTab5"), value: 3 }, // 订单关闭
        { label: this.$t("myCard.orderTab3"), value: 2 }, // 已支付
        { label: this.$t("myCard.orderTab3"), value: 4 }, // 已支付
      ],
      activeTab: 0,
      orderList: [],
      page: {
        limit: 10,
        currentPage: 1,
        total: 0,
      },
      timer: null,
      showLoading: false,
      giftList: [],
      showPayStatus: false,
      payStatus: -99,
      out_trade_no: "",
      message: "",
      isKr: this.$isKr,
    };
  },
  watch: {
    chooseRoleInfo(val) {
      if (val) {
        this.page.currentPage = 1;
        this.orderList = [];
        this.webItemList();
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      let scrollEnd = this.$refs.loadMore;
      let scrollEndPosition = scrollEnd
        ? scrollEnd.getBoundingClientRect().top
        : "";
      if (
        scrollEndPosition <= window.innerHeight &&
        !this.showLoading &&
        this.orderList.length > 0 &&
        this.orderList.length < this.page.total
      ) {
        this.loadMore();
      }
    },
    changeTab(item) {
      this.activeTab = item.value;
      this.page.currentPage = 1;
      this.orderList = [];
      this.myTrade();
    },
    loadMore() {
      this.page.currentPage++;
      this.myTrade();
    },
    myTrade() {
      this.showLoading = true;
      myTrade({
        role_id: this.chooseRoleInfo.role_id,
        status: this.activeTab,
        limit: this.page.limit,
        page: this.page.currentPage,
      })
        .then((res) => {
          this.page.total = res.data.total;
          res.data.trades.forEach((item) => {
            if (item.post_amount === 0) {
              // // 是点数卡
              // item.amount = -99;
              // if(item.status === 0) item.status = 3
            }

            if (item.currency_code && item.currency_code === "KRW") {
              item.currency_code = "₩";
            }
            item.hours = Math.floor(item.close_cd / 3600);
            item.minute = Math.floor(Math.floor(item.close_cd % 3600) / 60);
            item.second = item.close_cd % 60;

            if (!item.product_subject || item.product_subject === "") {
              if (item.product_id == 99999999) {
                item.product_subject = this.$t("myCard.extraTitle");
              } else if (item.product_id === "") {
                item.product_subject = this.$t("myCard.payWay2");
              } else {
                Object.values(this.giftList).forEach((list) => {
                  list.forEach((inner) => {
                    inner.products.forEach((each) => {
                      staticGiftList.forEach((gift) => {
                        if (gift.product_id == each.product_id) {
                          if (this.isEn) {
                            each.product_subject = gift.product_subject_en;
                          } else if (this.isKr) {
                            each.product_subject = gift.product_subject_kr;
                          } else {
                            each.product_subject = gift.product_subject;
                          }
                          if (!gift.icon) {
                            each.icon = gift.product_id;
                          }
                        }
                      });
                      // }
                      // productType = 305 or 306 名字写死
                      if (each.productType === 305) {
                        each.product_subject = "限時秒殺禮包";
                      } else if (each.productType === 306) {
                        each.product_subject = "金喵賜福禮包";
                      }
                      if (each.product_id == item.product_id) {
                        item.product_subject = each.product_subject;
                      }
                    });
                  });
                });
              }
            }
          });
          this.$forceUpdate();
          // this.orderList = res.data.trades;
          this.orderList = [...this.orderList, ...res.data.trades];
          this.showLoading = false;
          setTimeout(() => {
            this.countDown();
          }, 100);
        })
        .catch((error) => {
          this.showLoading = false;
          this.message = error.message;
        });
    },
    webItemList() {
      this.showLoading = true;
      webItemList({
        role_id: this.chooseRoleInfo.role_id || "",
        project_id: this.chooseRoleInfo.project_id || "",
        payway_id: "",
      })
        .then((res) => {
          this.giftList = res.data.common;
          this.myTrade();
        })
        .catch((error) => {
          this.showLoading = false;
          this.message = error.message;
        });
    },
    closeStatus() {
      this.page.currentPage = 1;
      this.orderList = [];
      this.myTrade();
      this.showPayStatus = false;
      this.payStatus = -99;
    },
    payQuery() {
      payQuery({ out_trade_no: this.out_trade_no }).then((res) => {
        this.payStatus = res.data.status || 0;
        if (this.payStatus === 2 || this.payStatus === 4) {
          this.page.currentPage = 1;
          this.orderList = [];
          this.myTrade();
          this.addPayTime();
        }
      });
    },
    addPayTime() {
      let arr = getLocalStorage("payTime")
        ? JSON.parse(getLocalStorage("payTime"))
        : "" || [];
      let flag = true;
      arr.forEach((item) => {
        if (
          item.open_id === JSON.parse(getLocalStorage("accountStorage")).open_id
        ) {
          item.payTime = Date.parse(new Date());
          item.role_id = this.chooseRoleInfo.role_id;
          flag = false;
        }
      });
      if (flag) {
        let obj = {
          open_id: JSON.parse(getLocalStorage("accountStorage")).open_id,
          payTime: Date.parse(new Date()),
          role_id: this.chooseRoleInfo.role_id,
        };
        arr.push(obj);
      }
      setLocalStorage("payTime", JSON.stringify(arr));
    },
    showStatus(item) {
      this.stateOption.forEach((each) => {
        if (each.value == item.status) {
          item.statusText = each.label;
        }
      });
      return item.statusText;
    },
    showPayBtns(item) {
      const arr = JSON.parse(getSessionStorage("configInit")).pay_ways;
      arr.forEach((each) => {
        if (each.type == item.pay_way_type) {
          if (each.type === "12") {
            item.payWay = "gash";
          } else if (each.type === "11") {
            item.payWay = "mycard";
          } else if (each.type === "15") {
            item.payWay = "paypal";
          } else if (each.type === "22") {
            item.payWay = "payletter";
          } else if (each.type === "18") {
            item.payWay = "payPalCard";
          }
        }
      });
      return item.payWay;
    },
    countDown() {
      clearInterval(this.timer);
      this.timer = setInterval(() => {
        this.orderList.forEach((item) => {
          if (item.close_cd && item.close_cd > 0) {
            if (item.hours === 0) {
              if (item.minute !== 0 && item.second === 0) {
                item.second = 59;
                item.minute -= 1;
              } else if (item.minute === 0 && item.second === 0) {
                item.second = 0;
                item.show = 0;
                clearInterval(this.timer);
                item.status = 3;
              } else {
                item.second -= 1;
              }
            } else {
              if (item.minute !== 0 && item.second === 0) {
                item.second = 59;
                item.minute -= 1;
              } else if (item.minute === 0 && item.second === 0) {
                item.hours -= 1;
                item.minute = 59;
                item.second = 59;
              } else {
                item.second -= 1;
              }
            }
            this.$forceUpdate();
          }
        });
      }, 1000);
    },
    formatTime(item) {
      let hoursStr = "";
      let minuteStr = "";
      let secondStr = "";
      hoursStr =
        item.hours.toString().length === 1 ? `0${item.hours}` : item.hours;
      minuteStr =
        item.minute.toString().length === 1 ? `0${item.minute}` : item.minute;
      secondStr =
        item.second.toString().length === 1 ? `0${item.second}` : item.second;
      if (item.hours / 24 > 2) {
        return Math.floor(item.hours / 24) + this.$t("myCard.days");
      } else if (item.hours / 24 > 1) {
        return Math.floor(item.hours / 24) + this.$t("myCard.days");
      } else {
        return hoursStr + ":" + minuteStr + ":" + secondStr;
      }
    },
    goPay(item) {
      this.showLoading = true;
      let url = window.location.origin + "/loading";
      let winOpen = window.open(url, "catPay");
      payQuery({ out_trade_no: item.out_trade_no })
        .then((res) => {
          this.showLoading = false;
          if (res.data.status === 0) {
            if (item.pay_way_type === "12") {
              //  gash1
              this.out_trade_no = item.out_trade_no;
              this.showPayStatus = true;
              let formData = `<form name="form1" id="form1" action=${item.transaction_url} method="post">
                  <input type="hidden" name="data" value=${item.auth_code}>
                  <input type="submit" value="send">
                </form>`;
              let newWindow = window.open("", "catPay");
              newWindow.document.write("<html><head></head><body>");
              let div = newWindow.document.createElement("div");
              div.innerHTML = formData;
              newWindow.document.body.appendChild(div);
              div.style.display = "none";
              newWindow.document.form1.submit();
            } else if (item.pay_way_type === "15") {
              //  paypal
              this.out_trade_no = item.out_trade_no;
              this.showPayStatus = true;
              setTimeout(() => {
                winOpen.location = item.transaction_url;
              }, 200);
            } else if (item.pay_way_type === "22") {
              //  payletter
              this.out_trade_no = item.out_trade_no;
              this.showPayStatus = true;
              setTimeout(() => {
                winOpen.location = item.transaction_url;
              }, 200);
            } else if (item.pay_way_type === "11") {
              //  myCard
              this.out_trade_no = item.out_trade_no;
              this.showPayStatus = true;
              setTimeout(() => {
                winOpen.location = item.transaction_url;
              }, 200);
            }
          } else {
            winOpen.close();
            this.page.currentPage = 1;
            this.orderList = [];
            this.myTrade();
          }
        })
        .catch((error) => {
          winOpen.close();
          this.showLoading = false;
          this.message = error.message;
        });
    },
    payClose(item) {
      this.showLoading = true;
      payClose({
        out_trade_no: item.out_trade_no,
        reason: "",
      })
        .then(() => {
          this.showLoading = false;
          this.message = this.$t("myCard.orderCancel");
          item.status = 3;
          this.$forceUpdate();
          this.page.currentPage = 1;
          this.orderList = [];
          this.myTrade();
        })
        .catch((error) => {
          this.showLoading = false;
          this.message = error.message;
        });
    },
  },
};
</script>

<style scoped></style>
